@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .afropoilitan {
        @apply font-['IBM Plex Mono']
    }
    .user-details-input {
        @apply outline-none border-b-2 border-b-[#454545] focus:border-b-[#454545] sm:w-[350px] xs:w-[320px] w-[280px] placeholder:text-[16px]
    }
    .pledge-button {
        @apply relative flex justify-center bg-white text-black font-semibold py-[15px] border-2 border-black max-w-[255px];
        @apply hover:bg-gray-100;
    }
    .pledge-button::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1.8px;
        bottom: -1px;
        left: 0;
        box-shadow: 4px 4px 0px 0px #FFD700;
        /* z-index: -1; */
        }
}

.IBM-mono {
    font-family: 'IBM Plex Mono', monospace;
}
.space-mono {
    font-family: 'Space Mono', monospace;
}
.inter-font {
    font-family: 'Inter', sans-serif;
}

.blog-grid {
    grid-column-gap: 32px;
    grid-row-gap: 64px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-bottom: 48px;
    display: grid
}

@media screen and (max-width: 768px) {
    .blog-grid {
        grid-template-columns: 1fr;
    }
}